import Vue from "vue"
import Vuex from "vuex"
import { getThemes, setThemes } from "@/utils/storage"

Vue.use(Vuex)

export default new Vuex.Store({
	state () {
		return {
			newRootStyles: [
				// 0th theme
				{
					"--header-bgc": "#f1f0f5",
					"--bg-color": "#fff",
					"--user-bgc": "#f7f6f9",
					"--main-color": "#f7f6f9",
					"--btn-color": "#f1f0f5",
					"--text-color1": "#454c53",
					"--text-color2": "#2e3337",
					"--text-color3": "#2e3337",
					"--text-color4": "#454c53",
					"--text-color5": "#8f55f2",
				},
				// 1th theme
				{
					"--header-bgc": "#1E1E1E",
					"--bg-color": "#2d2d2d",
					"--user-bgc": "#454545",
					"--main-color": "#69b4ff",
					"--btn-color": "#006fff",
					"--text-color1": "#e0e0e0",
					"--text-color2": "#ffffff",
					"--text-color3": "#69b4ff",
					"--text-color4": "#006fff",
					"--text-color5": "#006fff"
				},
				// 2nd theme
				{
					"--header-bgc": "#241b35",
					"--bg-color": "#342a45",
					"--user-bgc": "#4d425f",
					"--main-color": "#a364ff",
					"--btn-color": "#cb80ff",
					"--text-color1": "#e0e0e0",
					"--text-color2": "#ffffff",
					"--text-color3": "#a364ff",
					"--text-color4": "#cb80ff",
					"--text-color5": "#cb80ff"
				},
				// 3th theme
				{
					"--header-bgc": "#1D1F21",
					"--bg-color": "#2c2e30",
					"--user-bgc": "#444648",
					"--main-color": "#B33A3A",
					"--btn-color": "#ea6a64",
					"--text-color1": "#e0e0e0",
					"--text-color2": "#ffffff",
					"--text-color3": "#B33A3A",
					"--text-color4": "#ea6a64",
					"--text-color5": "#ea6a64"
				},
				// 4th theme
				{
					"--header-bgc": "#0D1F2D",
					"--bg-color": "#1d2e3d",
					"--user-bgc": "#354656",
					"--main-color": "#4a9d9c",
					"--btn-color": "#0D6E6E",
					"--text-color1": "#e0e0e0",
					"--text-color2": "#ffffff",
					"--text-color3": "#4a9d9c",
					"--text-color4": "#0D6E6E",
					"--text-color5": "#0D6E6E"
				},
				// 5th theme
				{
					"--header-bgc": "#1D1F21",
					"--bg-color": "#2c2e30",
					"--user-bgc": "#444648",
					"--main-color": "#FF6600",
					"--btn-color": "#ff983f",
					"--text-color1": "#e0e0e0",
					"--text-color2": "#ffffff",
					"--text-color3": "#FF6600",
					"--text-color4": "#ff983f",
					"--text-color5": "#ff983f"
				},
				// 6th theme
				{
					"--header-bgc": "#1E1E1E",
					"--bg-color": "#2d2d2d",
					"--user-bgc": "#454545",
					"--main-color": "#61bc84",
					"--btn-color": "#8FBC8F",
					"--text-color1": "#e0e0e0",
					"--text-color2": "#ffffff",
					"--text-color3": "#61bc84",
					"--text-color4": "#8FBC8F",
					"--text-color5": "#8FBC8F"
				}
			],
			sidebarOpen: true,
			themes: +getThemes()
		}
	},
	mutations: {
		SidebarShow (state) {
			state.sidebarOpen = !state.sidebarOpen
		},
		setStateThemes (state, value) {
			state.themes = value
			setThemes(value)
		},
		themeUpdate (state) {
			Object.keys(state.newRootStyles[state.themes]).forEach(key => {
				document.documentElement.style.setProperty(key, state.newRootStyles[state.themes][key]);
			});
		}
	},
})
