import Vue from "vue"

Vue.directive("hover", {
  bind (el) {
    el.addEventListener("mouseenter", () => {
      el.classList.add("hovered")
    })
    el.addEventListener("mouseleave", () => {
      el.classList.remove("hovered")
    })
  },
})

// v-focus 默认获取焦点
Vue.directive("focus", {
  inserted (el) {
    el.focus()
    el.querySelector("textarea").focus()
  },
})

export default Vue.directive