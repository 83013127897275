const INFO_KEY = 'themes'

export const getThemes = () => {
  const result = localStorage.getItem(INFO_KEY)
  const defaultInfo = 0
  return result || defaultInfo
}
export const setThemes = (value) => {
  localStorage.setItem(INFO_KEY, value)
}
export const removeThemes = () => {
  localStorage.removeItem(INFO_KEY)
}
