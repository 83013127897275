import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import router from "./router"
import "bootstrap/dist/css/bootstrap.min.css"
import "@/styles/base.css"
import "@/utils/directive"
import "@/utils/ElementUI"

Vue.config.productionTip = false

new Vue({
	render: (h) => h(App),
	store,
	router,
}).$mount("#app")
