import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

const routes = [
	{
		path: "/",
		redirect: "/handleBanner"
	},
	{
		path: "/",
		name: "Home",
		component: () => import("@/views/Home"),
		children: [
			{
				path: "/handleSort",
				name: "MainContainerSort",
				component: () => import("@/views/main_container/main_container_sort"),
			},
			{
				path: "/handleGoods",
				name: "MainContainerGoods",
				component: () => import("@/views/main_container/main_container_goods"),
			},
			{
				path: "/handleBanner",
				name: "MainContainerBanner",
				component: () => import("@/views/main_container/main_container_banner"),
			},
			{
				path: "/handleOrder",
				name: "MainContainerOrder",
				component: () => import("@/views/main_container/main_container_order"),
			}
		],
	},
	{ path: "*", redirect: "/" },
]

export default new Router({
	mode: "history",
	routes
})
